.contact.section {
  padding-bottom: 6.25rem;
}

.contact__container {
  grid-template-columns: 4fr 8fr;
  column-gap: 1.875rem;
}

.contact__title {
  font-size: var(--h3-font-size);
  margin-bottom: 0.5rem;
  text-align: center;
}

.contact__details {
  box-shadow: var(--shadow);
  background-color: hsl(164, 54%, 63%);
  color: #f8f9fa;
  border-radius: var(--border-radius);
  margin: 2rem 0.5rem 0 0.5rem;
  padding: 1.875rem;
}

.contact__form {
  padding: 0 2rem 4rem 0;
}

.contact__form-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.5rem;
}

.contact__form-div {
  position: relative;
  margin-bottom: 1.875rem;
  height: 3.75rem;
}

.contact__form-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: var(--shadow);
  background-color: var(--container-color);
  color: var(--text-color);
  border: none;
  outline: none;
  border-radius: 1.875rem;
  padding: 1rem 1.875rem;
  z-index: 1;
}

.contact__form-area {
  height: 10.25rem;
}

.contact__form-area textarea {
  resize: none;
}

@media screen and (max-width: 1024px) {
  .contact__container {
    grid-template-columns: 300px 360px;
    justify-content: center;
  }

  .contact__form {
    padding-right: 0rem;
  }

  .contact__form-group {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {
  .contact__container {
    grid-template-columns: 310px;
    row-gap: 1.875rem;
  }

  .contact__info {
    text-align: center;
  }

  .section__title {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 350px) {
  .contact__container {
    grid-template-columns: 1fr;
  }
}
